@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono);
@import url(https://fonts.googleapis.com/css?family=Cambay);
@import url(https://fonts.googleapis.com/css?family=Laila);
.tooltip-inner {
  white-space: pre-line;
}
.functional-pending-test-status {
  color: #007dbc !important;
}
.functional-passed-test-status {
  color: #1e8900 !important;
}
.functional-failed-test-status {
  color: #df3312 !important;
}
.functional-error-test-status {
  color: #df3312 !important;
}
.functional-running-test-status {
  color: #007dbc !important;
}
.functional-not-applicable-test-status {
  color: #879596 !important;
}
.ellipsis-anim-with-color span {
  opacity: 0;
  color: #007dbc !important;
  white-space: nowrap;
  -webkit-animation: ellipsis-dot 1s infinite;
  animation: ellipsis-dot 1s infinite;
}
.overall-validation-reason-style {
  font-size: x-small;
  white-space: nowrap;
  word-wrap: break-word !important;
  color: #8B4513 !important;
}
.nowrap-style {
  white-space: nowrap;
}
.utterances-button-style {
  color: #007dbc !important;
  text-decoration: underline;
}
.wrap-text-style {
  min-width: 300px !important;
  max-width: 300px !important;
  word-wrap:break-word !important;
}
.utterance-comments-style {
  font-size: x-small;
  word-wrap: break-word !important;
  color: #8B4513 !important;
}
.utterance-comments-failed-status-style {
  font-size: x-small;
  word-wrap: break-word !important;
  color: #df3312 !important;
}
.test-category-text-style {
  color: #4c4c4c !important;
}
.ref-mic-status-style {
  color: #696969 !important;
}
.ref-mic-online-style {
  color: #1e8900 !important;
}
.ref-mic-offline-style {
  color: #df3312 !important;
}
.ref-mic-unavailable-style {
  color: #007dbc !important;
}
.ref-mic-recording-dot-style {
  color: #df3312 !important;
  font-size: 400%;
}
.ref-mic-recording-text-style {
  color: #007dbc !important;
}
.ref-mic-recording-braces-style {
  color: #696969 !important;
}
.utterance_played_style {
    color: #007dbc !important;
  }
  .autolocal-pending-test-status {
    color: #007dbc !important;
  }
  .autolocal-passed-test-status {
    color: #1e8900 !important;
  }
  .autolocal-failed-test-status {
    color: #df3312 !important;
  }
  .autolocal-error-test-status {
    color: #df3312 !important;
  }
  .autolocal-running-test-status {
    color: #007dbc !important;
  }
  .autolocal-timeout-test-status {
    color: #4c4c4c !important;
  }
  .utterance-data-header-style {
    color: #4c4c4c !important;
  }
.filler {
  background: #1e8900;
  height: 100%;
  transition: width .2s ease-in;
}
.progress-bar {
  position: relative;
  height: 8px;
  width: 100%;
  border: 0px;
  border-radius: 0px;
}
.breadcrumb-style {
  display: table;
  margin: 0 auto;
  padding: inherit;
}
.alignleft {
    float: left;
}
.alignright {
    float: right;
}
.ellipsis-anim span {
    opacity: 0;
    -webkit-animation: ellipsis-dot 1s infinite;
    animation: ellipsis-dot 1s infinite;
}

.ellipsis-anim span:nth-child(1) {
    -webkit-animation-delay: 0.0s;
    animation-delay: 0.0s;
}
.ellipsis-anim span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}
.ellipsis-anim span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}
.ellipsis-anim span:nth-child(4) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

@-webkit-keyframes ellipsis-dot {
      0% { opacity: 0; }
     50% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes ellipsis-dot {
      0% { opacity: 0; }
     50% { opacity: 1; }
    100% { opacity: 0; }
}
div.scrollstylecontainer {
    max-height: 600px;
    overflow: scroll;
    color:#007dbc;
    font-size: medium;
}

.currentAction {
    font-size: 10px;
}

.utteranceFont {
    font-family: Courier, serif;
    font-size: large;
    font-weight: normal;
}

.questionPlayedFont {
    font-family: "Helvetica Neue", Roboto, Arial, sans-serif;
    font-size: large;
    font-weight: normal;
}

.notificationFont {
    font-family: "Trebuchet MS";
    font-size: large;
    font-weight: bold;
}

.f-container-col {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: normal;
    align-content: normal;
}


.f-container-center {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.f-item {
    display: block;
    margin: 25px 0 0 40px;
}


.Aligner-default {
    display: flex;
    flex-flow: column;
}

.small-container {
    height: 400px;
}

.Aligner {
    display: flex;
    align-items: stretch;
    justify-content: center;
    height: 250px;
}

.utteranceContainer {
    display: flex;
    flex-direction: column;
}

.utteranceText {
    font-family: Optima;
    font-size: 20px;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 33.6px;
}

.footerText {
    font-family: Optima;
    font-size: 13px;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 33.6px;
}

.notification-style {
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    font-size: 22px;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 52.8px;
    color: #2C9EDB;
    align-items: center;
    display: flex;
    align-self: center;
}

.positive {

    font-family: Roboto, sans-serif;
    font-weight: 10;
    font-size: 11px;
    color: #fff;
    background-color: #2db013;
    padding: 5px 5px;
    border: solid #01820c 2px;
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
    border-radius: 8px;
    transition: 1000ms;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: center;
    width: 200px;
}

.negative {

    font-family: Roboto, sans-serif;
    font-weight: 10;
    font-size: 11px;
    color: #fff;
    background-color: #e3394d;
    padding: 5px 5px;
    border: solid #910404 2px;
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
    border-radius: 8px;
    transition: 1000ms;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
    justify-content: center;
    width: 200px;
}

.info {

    font-family: Roboto, sans-serif;
    font-weight: 10;
    font-size: 11px;
    color: #fff;
    background-color: #0066CC;
    padding: 5px 5px;
    border: 2px solid #0066cc;
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
    border-radius: 8px;
    transition: 1000ms;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
    justify-content: center;
    width: 200px;
}

.flex-container {
    display: flex;
    height: 80%;
    padding: 5px;
    grid-gap: 5px;
    gap: 5px;
}

.bdd {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    letter-spacing: 0.2px;
    word-spacing: 0.6px;
    color: #000000;
    font-weight: 700;
    -webkit-text-decoration: none solid rgb(68, 68, 68);
            text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: uppercase;
}

.bddtext {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    text-align: center;
    font-size: 15px;
    letter-spacing: 1px;
    word-spacing: -3px;
    color: rgb(0, 0, 0);
}

.cardheight {
    height: 200px;
}
.upload-file-container {
    display: flex;
    align-items: center;
  }
  
  .hidden-input {
    opacity: 0;
    max-width: 80px;
  }
  
  .button-label {
    position: absolute;
    left: 40px;
    top: 5px;
  }



.f-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: normal;
    align-content: normal;
    margin-bottom:5px;
}

.f-item {
    margin-right: 5px;
    margin-left: 5px;
}

.header-content {
  font-size: 50px;
  margin-bottom: 10px;
  height: 50px;
  font-weight: 500;
}

.container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  justify-content: center;
}

.newSystemApp {
  background-color: var(--awsui-color-grey-150);
  padding: 10px;
  margin: 20px;
  min-height: 100vh;
  position: relative;
}

.testExecutionList .awsui-table-row:not(.awsui-table-row-merged):hover {
  cursor: pointer;
  background: var(--awsui-color-grey-100);
}

.formErrMsg {
  color: var(--awsui-color-text-status-error);
}

.deleteTestPopup,
.executeTestPopup {
  margin: -20px;
}

.newSystemApp .awsui-modal-container {
  display: block;
}

.newSystemApp .awsui-modal-container .awsui-modal-dialog {
  top: 20%;
}

