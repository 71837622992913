.header-content {
  font-size: 50px;
  margin-bottom: 10px;
  height: 50px;
  font-weight: 500;
}

.container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  justify-content: center;
}
