.utterance_played_style {
    color: #007dbc !important;
  }
  .autolocal-pending-test-status {
    color: #007dbc !important;
  }
  .autolocal-passed-test-status {
    color: #1e8900 !important;
  }
  .autolocal-failed-test-status {
    color: #df3312 !important;
  }
  .autolocal-error-test-status {
    color: #df3312 !important;
  }
  .autolocal-running-test-status {
    color: #007dbc !important;
  }
  .autolocal-timeout-test-status {
    color: #4c4c4c !important;
  }
  .utterance-data-header-style {
    color: #4c4c4c !important;
  }