
.currentAction {
    font-size: 10px;
}

.utteranceFont {
    font-family: Courier, serif;
    font-size: large;
    font-weight: normal;
}

.questionPlayedFont {
    font-family: "Helvetica Neue", Roboto, Arial, sans-serif;
    font-size: large;
    font-weight: normal;
}

.notificationFont {
    font-family: "Trebuchet MS";
    font-size: large;
    font-weight: bold;
}

.f-container-col {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: normal;
    align-content: normal;
}


.f-container-center {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.f-item {
    display: block;
    margin: 25px 0 0 40px;
}


.Aligner-default {
    display: flex;
    flex-flow: column;
}

.small-container {
    height: 400px;
}

.Aligner {
    display: flex;
    align-items: stretch;
    justify-content: center;
    height: 250px;
}

.utteranceContainer {
    display: flex;
    flex-direction: column;
}

.utteranceText {
    font-family: Optima;
    font-size: 20px;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 33.6px;
}

.footerText {
    font-family: Optima;
    font-size: 13px;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 33.6px;
}

.notification-style {
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 52.8px;
    color: #2C9EDB;
    align-items: center;
    display: flex;
    align-self: center;
}
