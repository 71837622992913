.testExecutionList .awsui-table-row:not(.awsui-table-row-merged):hover {
  cursor: pointer;
  background: var(--awsui-color-grey-100);
}

.formErrMsg {
  color: var(--awsui-color-text-status-error);
}

.deleteTestPopup,
.executeTestPopup {
  margin: -20px;
}

.newSystemApp .awsui-modal-container {
  display: block;
}

.newSystemApp .awsui-modal-container .awsui-modal-dialog {
  top: 20%;
}
