.upload-file-container {
    display: flex;
    align-items: center;
  }
  
  .hidden-input {
    opacity: 0;
    max-width: 80px;
  }
  
  .button-label {
    position: absolute;
    left: 40px;
    top: 5px;
  }